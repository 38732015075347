$main-brown: #534641;
$main-green: #006737;

.nav__container {
    width: 50%;
    color: white;
    padding: 0.5rem 0.5rem;
    background-color: $main-brown;
    border: 0.1rem solid #fff;
    border-right: 0;
    box-shadow: 5px 5px $main-green;
    margin: 1rem 0 2.25rem 0;
    font-size: 0.75rem;

    .nav__bar {
        list-style: none;
        padding-bottom: 1.5rem;
        border-width: 0.1rem;;
        border-style: none none dotted none;
        border-color: #fff;

        .nav__item {
            a {
                color: white;
                text-decoration: none;
            }
        
            a:hover, 
            a:focus, 
            a:active {
                color: black;
                background-color: white;
                font-weight: bold;
                font-style: italic;
                text-decoration: underline dotted;
            }
        }
    }

    .nav__inputFormat {
        display: flex;

        label {
            font-size: 0.7rem;
        }

        #nav__input {
            background-color: $main-brown;
            color: #fff;
            border: none;
            font-family: 'Source Code Pro', monospace;
            font-weight: 600;
            margin-left: 0.2rem;
            display: block;
            outline: none;
            width: 50%;
        
            animation: blink-color 1s infinite;
            border-left: transparent solid 3px;
            
        
            &:focus {
                border: none;
            }
        
            @keyframes blink-color {
                50% {border-color: #fff;}
            }
        }
    }
}
