$blue: #232ade;
$neon-green: #28e02f;
$main-green: #006737;
$main-brown: #534641;
$background-color: #faeede;

@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;400;700&display=swap');

@import 'content';
@import 'home';
@import 'list';
@import 'nav';