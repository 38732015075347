.list {
  width: 50%;
  background-color: $main-brown;
  color: #fff;
  margin: 1rem 0 2.25rem 0;
  padding: 20px 10px;
  border: 0.1rem solid #fff;
  box-shadow: 5px 5px $main-green;
  font-size: 0.75rem;

  ul {
    list-style-type: none;
    margin-right: 1rem;

    li {
      border: 0.1rem solid white;
      padding: 0.3rem 0.6rem;

      &:hover {
        background-color: white; 
        color: $main-brown;
        font-weight: bold;
        text-decoration: underline dotted;
      }
    }
  }
}

.listContent {
  border-style: dotted;
  border-color: #fff;
  padding: 0 1.5rem;
  margin-right: 0.3rem;
  border-width: 0.1rem;
  height: 100%;

  a {
    color: white;
    background-color: $main-brown;
    text-decoration: underline dotted;

    &:hover, 
    &:focus, 
    &:active {
        color: black;
        background-color: white;
        font-weight: bold;
        font-style: italic;
        text-decoration: underline dotted;
    }
  }

  .tidbit {
    background-color: yellow;
    color: #000;
    font-size: 0.8rem;
    padding: 0.5rem 0.8rem;
  }
}