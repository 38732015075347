.home__container {
    box-sizing: border-box;
    min-height: 100vh;
    padding: 0.5rem 0;
    display: flex;
    flex-direction: column;
    font-family: 'Roboto Mono';
    color: $main-green;
  
    .home__title {
      font-weight: 400;
      padding: 0 0 0.5rem 1rem;
      border-style: none none solid none;
      border-width: 0.1rem;
      border-color: $main-green;
      width: 100%;
      margin: 0;
  
      a:hover, a:focus, a:active {
        font-weight: bold;
      }
    }
  
    .home__divider {
      display: flex;
      padding: 0 1rem;
      min-height: 100vh;
  
      .home__navHolder {
        width: 35%;
        padding-right: 2rem;
        border-right: 0.1rem solid $main-green;
  
        .home__about {
          width: 98%;
          padding:  0 0 0 0.5rem;
          background: rgb(252,237,34);
          background: linear-gradient(90deg, rgba(252,237,34,0.7679665616246498) 0%, rgba(254,122,172,0.4318321078431373) 58%, rgba(255,255,255,1) 84%);
          border: 0.1rem solid $main-green;
          font-size: 0.8rem;
          font-weight:700;
      
          a {
            text-decoration: underline dotted;
          }
      
          a:hover, 
          a:focus,
          a:active {
            background-color: #000000;
            color: #FFFFFF;
            font-style: italic;
            font-weight: bold;
          }
        }
  
        .home__consoleHolder {
          display:flex;
          width: 100%;
        }
  
        .home__logo {
          width: 25%;
          padding-left: 35%;
        }

        @media screen and (max-width: 600px) {
          width: 90%;
          flex-direction: column;
        }
      }

      .home__contentHolder {
        width: 60%;

        @media screen and (max-width: 600px) {
          width: 90%;
          flex-direction: column;
        }
      }

      @media screen and (max-width: 600px) {
        width: 100%;
        flex-direction: column;
      }
    }
  
    footer {
      width: 100%;
      height: 100px;
      padding-left: 1rem;
      font-size: 0.8rem;
      margin-top:30%;
    }
  
    a {
      color: inherit;
      text-decoration: none;
    }
  
    .logo {
      height: 1em;
    }
  
    @media screen and (max-width: 600px) {
      .home__container {
        .home__divider {
          width: 100%;
          flex-direction: column;
  
          .home__consoleHolder {
            width: 100%;
          }
  
          .home__contentHolder {
            width: 100%;
          }
        }
      }
      .grid {
        width: 100%;
        flex-direction: column;
      }
    }
  }